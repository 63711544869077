<template>
  <div id="topnav">
    <div class="topmenu">
        <div class="navigation wrap">
            <ul class="bar">
                <li
                    v-for="(item, index) in navList"
                    :key="index"
                    @click="handleClick(index)"
                    :class="{ navActive: index == change || item.url == $route.path }"
                    >
                    <router-link :to="item.url" exact router-link-active>
                        {{ item.text }}
                    </router-link>
                    <i class="dot"></i>
                </li>
            </ul>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            change: null,
            navList: [
                { url: '/', text: '首页' },
                { url: '/result', text: '获证查询' },
                { url: '/professionalInstitutions', text: '从业机构' },
                { url: '/inspectionStandard', text: '检验标准' }
            ]
        }
    },
    methods: {
        handleClick: function (index) {
            this.change = index
            localStorage.clear()
        },
        checkRouterLocal(path) {
            this.change = this.navList.findIndex(item => item.url === path);
            if(path == '/details'){
                this.change = 1;
            }
        }
    },
    mounted() {
        let newRouter = this.$router.currentRoute.fullPath
        this.navList.forEach((item, index) => {
            if (newRouter === item.url) {
                this.change = index
            }
        })
        if (this.$route.path == '/') {
            this.change = 0
        }else if(this.$route.path == '/details'){
            this.change = 1;
        }

    },
    watch: {
        $route: function (to) {
            if (to.path == '/') {
                this.change = 0
            }
            let path = this.$route.path;
            this.checkRouterLocal(path);
        }
    }
};
</script>

<style lang="scss" scoped>
#topnav {
    position: relative;
    width: 100%;
    height: 400px;
    margin: 0 auto;
    background: url('../assets/banner.jpg') no-repeat 0 0;
    background-size: 100% 100%;
}
.topmenu {
    position: absolute;
    left:0;
    bottom:22px;
    width: 100%;
}
.topmenu .navigation{
    width: 1200px;
    margin:0 auto;
}
.topmenu .bar {
    margin:0 auto;
    text-align: center;
    font-family: 'PingFangSC-Regular';
}

.topmenu li {
    position: relative;
    display: inline-block;
    width: 128px;
    height: 44px;
    line-height: 44px;
    margin-right:48px;
    text-align: center;
    letter-spacing: normal;
    background: rgba(255,255,255,0.7);
}
.topmenu li a {
    display: inline-block;
    width: 100%;
    font-size: 16px;
    color: #323233;
    text-align: center;
}
.topmenu li .dot{
    position: absolute;
    left:50%;
    margin-left:-6px;
    bottom:-6px;
    width:0; 
    height:0; 
    border-left:6px solid transparent;
    border-right:6px solid transparent;
    border-top:6px solid hsla(0,0%,100%,.7);
}
.topmenu .bar .navActive{
    position: relative;
    background-color: #031B57;
}
.topmenu .bar .navActive .dot{
    position: absolute;
    left:50%;
    margin-left:-6px;
    bottom:-6px;
    width:0; 
    height:0; 
    border-left:6px solid transparent;
    border-right:6px solid transparent;
    border-top:6px solid #031B57;
}
.topmenu .bar .navActive>a{
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 3px;
}
</style>
