// eslint-disable-next-line
import { BasicLayout } from '@/layouts'

/**
 * 基础路由
//  * @type { *[] }
 */

export let constantRouterMap = [
    {
        path: '/',
        component: BasicLayout,
        redirect: '/',
        children: [{
                path: '/',
                name: 'index',
                component: () => import("@/views/index")
            },
            {
                path: 'result',
                name: 'result',
                component: () => import("@/views/certificate/result"),
            },
            {
                path: 'professionalInstitutions',
                name: 'professionalInstitutions',
                component: () => import("@/views/professionalInstitutions")
            },
            {
                path: 'inspectionStandard',
                name: 'inspectionStandard',
                component: () => import("@/views/inspectionStandard")
            },
            {
                path: '/details',
                name: 'details',
                component: () => import("@/views/certificate/components/details")
            }
        ]
    },
    {
        path: '/countriesDetection',
        name: 'countriesDetection',
        component: () => import("@/views/components/countriesDetection")
    },
    {
        path: '/certificationBody',
        name: 'certificationBody',
        component: () => import("@/views/components/certificationBody")
    },
    {
        path: '/certifiedLaboratory',
        name: 'certifiedLaboratory',
        component: () => import("@/views/components/certifiedLaboratory")
    },
    {
        path: '/foodRecheck',
        name: 'foodRecheck',
        component: () => import("@/views/components/foodRecheck")
    },
    {
        path: '/NationalQualification',
        name: 'NationalQualification',
        component: () => import("@/views/components/NationalQualification")
    },
    {
        path: '/productCertification',
        name: 'productCertification',
        component: () => import("@/views/components/productCertification")
    },
    {
        path: '/provincialQualification',
        name: 'provincialQualification',
        component: () => import("@/views/components/provincialQualification")
    }
]