<template>
  <el-row>
    <el-col :span="24">
      <header>
        <div>
          <heads />
        </div>
      </header>
      <router-view />
      <footers />
    </el-col>
  </el-row>
</template>
<script>
import heads from '@/components/heads'
import footers from '@/components/footers'
export default {
    components: {
        heads,
        footers
    }
}
</script>