import Vue from 'vue'
import Router from 'vue-router'
import { constantRouterMap } from '@/config/router.config'


Vue.use(Router)
const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRouterMap
})

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
      document.title = to.meta.title;
    }
    if (to.path) {
      window._hmt.push(["_trackPageview", "/#" + to.fullPath]);
    }
    next();
  });

export default router